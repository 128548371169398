function isWechat(UA) {
    return /MicroMessenger/i.test(UA) ? true : false
}

// function isWeibo(UA) {
//     return /Weibo/i.test(UA) ? true : false
// }

// function isQQ(UA) {
//     return /QQ/i.test(UA) ? true : false
// }

function isMobile(UA) {
    return /(Android|ios|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(UA) ? true : false
}

function isIOS(UA) {
    return /iPhone|iPad|iPod|ios/i.test(UA) ? true : false
}

function isAndroid(UA) {
    return /Android/i.test(UA) ? true : false
}

// 百度小程序环境
function isWebswan(UA) {
    return /swan\//.test(UA) ? true : false
}

// 微信小程序环境
function isWxMini(UA) {
    return /miniprogram/i.test(UA) ? true : false
}

function isTabletPc(UA) {
    let state = /(iPad|PlayBook)/i.test(UA) || (isAndroid(UA) && !/(?:Mobile)/.test(UA)) ? true : false
    return state
}

function isChrome(UA) {
    return /chrome/i.test(UA) ? true : false
}

export default function deviceInfo() {
    const UA = window.navigator.userAgent
    let mobile = isMobile(UA)
    const wechat = isWechat(UA)
    const ios = isIOS(UA)
    const android = isAndroid(UA)
    const webswan = isWebswan(UA)
    const wxMini = isWxMini(UA)
    const tabletPc = isTabletPc(UA)
    let pc = !mobile
    if (wxMini || webswan) {
        pc = false
        mobile = true
    }
    return {
        mobile,
        wechat,
        ios,
        android,
        pc,
        baiduSwan: webswan,
        wxMini,
        UA,
        tabletPc,
        chrome: isChrome(UA),
    }
}
