<template>
    <van-popup class="verification-popup" @close="onClose" v-model="show" get-container="body">
        <div class="container">
            <img
                v-if="show"
                @click="onResetNum"
                class="verification-img"
                :src="`${numVerificationUrl}${telNo}&rd=${numVerificationRd}`"
            />
            <van-icon @click="onResetNum" name="replay" />
            <input v-model="numVerification" placeholder="请输入" />
        </div>
        <div class="opts">
            <van-button plain @click="onClose">取消</van-button>
            <van-button class="submit-btn" @click="onSubmit">确认</van-button>
        </div>
    </van-popup>
</template>
<script>
import { getCaptcha } from '@/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'telNo'],
    data() {
        return {
            show: false,
            numVerification: '',
            numVerificationUrl: '',
            numVerificationRd: 1,
        }
    },
    computed: {
        ...mapGetters(['deviceInfo', 'SEVER_PATH_BEGONIA']),
    },
    created() {
        this.numVerificationUrl = `${this.SEVER_PATH_BEGONIA}/user/getKaptcha?tel=`
    },
    watch: {
        isShow: function (isShow) {
            this.numVerification = ''
            this.show = isShow
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
        onResetNum() {
            this.numVerificationRd += 1
        },
        onSubmit() {
            getCaptcha({
                tel: this.telNo,
                kaptcha: this.numVerification,
            }).then(() => {
                this.show = false
                this.$toast.success('发送成功')
                this.$emit('success')
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.verification-popup {
    width: 360px;
    border-radius: 4px;
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 32px auto 10px;
        .verification-img {
            width: 88px;
            height: 32px;
            margin-right: 10px;
        }
        .van-icon-replay {
            cursor: pointer;
            font-size: 18px;
        }
        input {
            margin-left: 40px;
            height: 32px;
            width: 120px;
            border: 1px solid #dedede;
            display: block;
            border-radius: 4px;
            padding: 4px 8px;
            text-align: center;
        }
    }
    .opts {
        display: flex;
        justify-content: space-around;
        padding: 44px 32px 24px;
        .van-button {
            width: 100px;
            height: 36px;
            border-radius: 4px;
            font-size: 14px;
        }
        .submit-btn {
            border: none;
            background: $theme-color;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 720px) {
    .verification-popup {
        width: 280px;
        .container {
            margin: 24px auto 10px;
            .verification-img {
                width: 72px;
                height: 32px;
                margin-right: 10px;
            }
            .van-icon-replay {
                cursor: pointer;
                font-size: 18px;
            }
            input {
                margin-left: 24px;
                height: 24px;
                width: 72px;
                border: 1px solid #dedede;
                display: block;
                border-radius: 4px;
                padding: 4px 8px;
                text-align: center;
            }
        }
        .opts {
            display: flex;
            padding: 24px 32px 16px;
            .van-button {
                width: 80px;
                height: 32px;
            }
        }
    }
}
</style>
