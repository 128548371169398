const getters = {
    userInfo: (state) => state.user.userInfo,
    isShowLoginDialog: (state) => state.user.isShowLoginDialog,

    SEVER_PATH_NUWA: (state) => state.global.SEVER_PATH_NUWA,
    SEVER_PATH_BEGONIA: (state) => state.global.SEVER_PATH_BEGONIA,
    SEVER_PATH_CUPID: (state) => state.global.SEVER_PATH_CUPID,
    WX_APPID: (state) => state.global.WX_APPID,
    ALI_APPID: (state) => state.global.ALI_APPID,
    PROJECT_PATH: (state) => state.global.PROJECT_PATH,
    deviceInfo: (state) => state.global.deviceInfo,
}

export default getters
