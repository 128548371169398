<template>
    <div>
        <van-popup v-model="isShow" class="login" get-container="body" :close-on-click-overlay="false">
            <div class="login-container" v-if="!userInfo">
                <div class="title">
                    <span>欢迎登录</span>
                    <img src="@/assets/images/login-bg.png" />
                </div>
                <div class="qrcode" v-if="loginType === 'wx'">
                    <div class="subtitle">
                        <img src="@/assets/images/wx-icon.png" />
                        <span>使用微信扫码即可登录</span>
                    </div>
                    <img
                        class="wx-qrcode"
                        :style="`opacity: ${isQrcodeRestart ? '0.2' : '1'}`"
                        v-show="wxminiQrcode"
                        :src="wxminiQrcode"
                    />
                    <canvas
                        v-show="!wxminiQrcode"
                        :style="`opacity: ${isQrcodeRestart ? '0.2' : '1'}`"
                        class="wx-qrcode"
                        ref="wxQrcode"
                    ></canvas>
                    <button @click="getWxMpLoginQRCodeApi" v-show="isQrcodeRestart" class="restart-btn">
                        刷新二维码
                    </button>
                </div>
                <div class="tel-code" v-if="loginType === 'code'">
                    <div class="item">
                        <span class="label">手机号</span>
                        <input v-model="telNo" placeholder="请输入您的手机号" />
                    </div>
                    <div class="item">
                        <span class="label">验证码</span>
                        <input v-model="code" @input="onCodeChange" placeholder="请输入验证码" />
                        <div class="code-btn" :class="hasGetCode ? '' : 'disabled-state'" @click="onCheckTel">
                            {{ hasGetCode ? '获取验证码' : `${timeNum}s` }}
                        </div>
                    </div>
                    <div class="login-btn" @click="onLogin">登录</div>
                </div>
                <div class="agm">
                    <span>登录即代表您已阅读并且同意</span>
                    <a target="_blank" href="/agmPrivacy">《隐私政策》</a>
                </div>

                <div class="opt">
                    <div @click="onSwitch('wx')" class="wx-login-btn" v-if="loginType === 'code' && deviceInfo.pc">
                        <img src="@/assets/images/wx-icon.png" />
                        <span>微信登录</span>
                    </div>
                    <div @click="onSwitch('code')" class="tel-login-btn" v-if="loginType === 'wx'">
                        <img src="@/assets/images/tel-icon.png" />
                        <span>手机号登录</span>
                    </div>
                </div>
            </div>
            <img v-if="!userInfo" @click="onHide" class="close-icon" src="@/assets/images/close-icon.png" />
        </van-popup>
        <VerificationPopup @success="onSendSuccess" :isShow.sync="isShowVerificationPopup" :telNo="telNo" />
        <EditUserTelPopup :isShow.sync="isShowEditUserTelPopup" :userInfo="userInfo" @success="onBindTel" />
    </div>
</template>
<script>
import VerificationPopup from './VerificationPopup'
import EditUserTelPopup from './EditUserTelPopup'
import { mAuthUserInfo } from '@/utils/login'
import { loginByCaptcha, getOAuthTpTicket, userLogin } from '@/api/userApi.js'
import { mapGetters } from 'vuex'
const QRCode = require('qrcode')

let timer = null
const setIntervalTime = 60
export default {
    data() {
        return {
            isShow: false,
            loginType: 'wx',
            telNo: '',
            code: '',
            hasGetCode: true,
            timeNum: setIntervalTime,

            isQrcodeRestart: false,
            isShowVerificationPopup: false,

            userInfo: null,
            isShowEditUserTelPopup: false,

            wxminiQrcode: null,
        }
    },
    components: {
        VerificationPopup,
        EditUserTelPopup,
    },
    watch: {
        '$store.state.user.isShowLoginDialog': function () {
            // 移动端
            if (this.deviceInfo.mobile) {
                // 微信环境直接授权登录
                if (this.deviceInfo.wechat && !this.$route.query.tpTicket) {
                    getOAuthTpTicket({ type: 'OAUTH_H5' }).then((res) => {
                        mAuthUserInfo(res.data.tpTicket)
                    })
                } else if (!this.deviceInfo.wechat) {
                    this.loginType = 'code'
                    this.isShow = true
                }
            } else {
                // pc 登录
                this.isShow = true
                if (this.loginType === 'wx') {
                    this.getWxMpLoginQRCodeApi()
                }
            }
        },
        '$route.query.tpTicket': function () {
            this.onWechatLogin()
        },
    },
    computed: { ...mapGetters(['deviceInfo', 'WX_APPID']) },
    created() {
        this.userInfo = this.$store.state.user.userInfo
    },
    destroyed() {
        clearInterval(timer)
    },
    methods: {
        onWechatLogin() {
            // 未登录 在微信环境下 且有tpTicket 直接登录
            const { deviceInfo } = this
            const { tpTicket } = this.$route.query
            if (deviceInfo.wechat && deviceInfo.mobile && !this.userInfo && tpTicket) {
                if (typeof tpTicket === 'string') {
                    this.loginByAuthTicketApi(tpTicket)
                } else {
                    this.loginByAuthTicketApi(tpTicket.pop())
                }
            }
        },

        onSwitch(type) {
            this.loginType = type
            clearInterval(timer)
            this.isQrcodeRestart = false
            this.hasGetCode = true
            if (this.loginType === 'wx') {
                this.getWxMpLoginQRCodeApi()
            }
        },
        onCheckTel() {
            const rule = new RegExp(/^1[0-9]{10}$/)
            if (!rule.test(this.telNo)) {
                return this.$toast('请输入正确的手机号')
            }
            if (!this.hasGetCode) return
            this.isShowVerificationPopup = true
        },
        onCodeChange() {
            this.code = this.code.substr(0, 6)
        },
        onSendSuccess() {
            this.hasGetCode = false
            clearInterval(timer)
            timer = setInterval(() => {
                this.timeNum -= 1
                if (this.timeNum <= 0) {
                    clearInterval(timer)
                    timer = null
                    this.hasGetCode = true
                    this.timeNum = setIntervalTime
                }
            }, 1000)
        },
        getWxMpLoginQRCodeApi() {
            this.isQrcodeRestart = false
            let params = { type: 'OAUTH_H5' }
            if (this.$route.query.wxmini) {
                params = { type: 'OAUTH_WXMA', appId: 'wx93ad102e24de3ea8' }
            }
            getOAuthTpTicket(params).then((res) => {
                if (params.type === 'OAUTH_H5') {
                    QRCode.toCanvas(
                        this.$refs.wxQrcode,
                        res.data.authUrl,
                        { width: 240, height: 240, margin: 2, errorCorrectionLevel: 'L' },
                        () => {}
                    )
                } else {
                    this.wxminiQrcode = res.data.qrcodeUrl
                }
                let timeNum = 0
                clearInterval(timer)
                timer = setInterval(() => {
                    userLogin({
                        type: 'auth_ticket',
                        authTicket: res.data.tpTicket,
                        appId: this.WX_APPID,
                    }).then((data) => {
                        if (data.data) {
                            if (data.data.telNo) {
                                this.loginSuccess(data.data)
                            } else {
                                this.userInfo = data.data
                                this.isShowEditUserTelPopup = true
                            }
                            clearInterval(timer)
                        }
                    })
                    timeNum += 3
                    if (timeNum >= 5 * 60) {
                        clearInterval(timer)
                        this.isQrcodeRestart = true
                        this.$toast('二维码已过期')
                    }
                }, 3000)
            })
        },
        onHide() {
            clearInterval(timer)
            this.isShowVerificationPopup = false
            this.isQrcodeRestart = false
            this.hasGetCode = true
            this.loginType = 'wx'
            this.isShow = false
            this.telNo = ''
            this.code = ''
        },
        onLogin() {
            var ruleTel = new RegExp(/^1[0-9]{10}$/)
            var ruleCode = new RegExp(/^[0-9]{4,8}$/)
            if (!ruleTel.test(this.telNo)) {
                return this.$toast('请输入正确的手机号')
            } else if (!ruleCode.test(this.code)) {
                return this.$toast('请输入正确的验证码')
            }
            // 提交
            loginByCaptcha({
                tel: this.telNo,
                captcha: this.code,
                pos: this.$route.name,
            }).then((res) => {
                this.loginSuccess(res.data)
            })
        },
        loginSuccess(data) {
            this.$toast.success('登录成功')
            this.isShow = false
            this.$store.dispatch('user/login', data).then(() => {})
            if (this.$route.query.redirect) {
                this.$router.push(decodeURIComponent(this.$route.query.redirect))
            }
        },
        onBindTel(tel) {
            this.loginSuccess({
                ...this.userInfo,
                telNo: tel,
            })
        },
        loginByAuthTicketApi(tpTicket) {
            userLogin({
                type: 'auth_ticket',
                authTicket: tpTicket,
                appId: this.WX_APPID,
            }).then((res) => {
                if (res.data) {
                    if (res.data.telNo) {
                        this.loginSuccess(res.data)
                    } else {
                        this.userInfo = res.data
                        this.isShow = true
                        // 等待渲染完成在显示弹窗
                        this.$nextTick(() => {
                            this.isShowEditUserTelPopup = true
                        })
                    }
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    background: none;
    width: 360px;
    height: 560px;
    overflow: initial;
    .close-icon {
        margin: 24px auto 0;
        width: 36px;
        height: 36px;
        background: none;
        cursor: pointer;
        display: block;
    }
}

.login-container {
    width: 100%;
    border-radius: 8px;
    background: #ffffff;
    position: relative;
    img {
        background: none;
    }
    .title {
        position: relative;
        height: 110px;
        img {
            position: absolute;
            width: 240px;
            right: -20px;
            bottom: 0;
        }
        span {
            font-size: 24px;
            font-weight: 700;
            position: absolute;
            top: 50px;
            left: 32px;
        }
    }
    .qrcode {
        position: relative;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .subtitle {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #333;
            img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        .wx-qrcode {
            margin-top: 12px;
            width: 240px;
            height: 240px;
        }
        .restart-btn {
            font-size: 14px;
            border: none;
            background: #999;
            color: #fff;
            line-height: 30px;
            width: 120px;
            border-radius: 2px;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }
    .tel-code {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 64px;
        .item {
            margin-bottom: 35px;
            width: 300px;
            border-radius: 4px;
            box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
            height: 42px;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
        }
        .label {
            font-size: 14px;
            font-weight: 700;
            padding: 0 10px 0 12px;
            line-height: 1.6em;
            border-right: 1px solid #efefef;
            flex-shrink: 0;
        }
        input {
            font-size: 15px;
            border: none;
            padding: 0 16px 0 10px;
            flex-grow: 1;
        }
        .code-btn {
            position: absolute;
            right: 0;
            top: 0;
            width: 96px;
            height: 100%;
            background: $theme-color;
            border-radius: 0px 4px 4px 0px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            cursor: pointer;
        }
        .disabled-state {
            color: #666666;
            background: #eeeeee;
        }
        .login-btn:active {
            opacity: 0.9;
        }
        .login-btn {
            margin: 24px 0 16px;
            width: 280px;
            height: 42px;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 2px;
            color: #ffffff;
            background: $theme-color;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .agm {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #99948d;
        line-height: 20px;
        a {
            color: $theme-color;
        }
    }
    .opt {
        padding: 20px 0;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }
}

@media screen and (max-width: 720px) {
    .login {
        width: 280px;
        height: 440px;
        .close-icon {
            margin: 18px auto 0;
            width: 28px;
            height: 28px;
        }
    }

    .login-container {
        border-radius: 4px;
        .title {
            height: 80px;
            img {
                width: 180px;
            }
            span {
                font-size: 20px;
                top: 36px;
            }
        }
        .qrcode {
            padding-top: 24px;
            .subtitle {
                font-size: 16px;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }
            }
            .wx-qrcode {
                margin-top: 10px;
                width: 180px !important;
                height: 180px !important;
            }
            .restart-btn {
                font-size: 12px;
                width: 100px;
            }
        }
        .tel-code {
            padding-top: 42px;
            .item {
                margin-bottom: 28px;
                width: 240px;
                height: 36px;
            }
            .label {
                font-size: 13px;
            }
            input {
                font-size: 14px;
            }
            .code-btn {
                width: 72px;
                font-size: 12px;
            }
            .login-btn {
                margin: 8px 0 16px;
                width: 220px;
                height: 36px;
                font-size: 14px;
            }
        }
        .agm {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #99948d;
            line-height: 20px;
            a {
                color: $theme-color;
            }
        }
        .opt {
            padding-top: 12px;
            font-size: 14px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
}
</style>
