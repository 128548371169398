import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import global from './modules/global'
import paper from './modules/paper'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: { user, global, paper },
    getters,
})

export default store
