import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import VueLazyload from 'vue-lazyload'
import '@vant/touch-emulator'
import '@/scss/reset.scss'
import '@/scss/tools.scss'
import 'vant/lib/index.css'
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false

Vue.use(Vant)
Vue.use(VueLazyload)

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 需要登录
    const { userInfo } = store.state.user
    if (to.meta.login && !userInfo) {
        router.push(`/?redirect=${encodeURIComponent(to.fullPath)}`)
    }
    next()
})

store.dispatch('global/setDeviceInfo').then(() => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app')
})
