const state = {
    activeSubjectTag: '',
    currentPage: 1,
    activeTags: {},
}

const mutations = {
    SET_ACTIVE_SUBJECT_TAG: (state, activeSubjectTag) => {
        state.activeSubjectTag = activeSubjectTag
    },
    SET_ACTIVETAGS: (state, activeTags) => {
        state.activeTags = activeTags
    },
    SET_CURRENT_PAGE: (state, page) => {
        state.currentPage = page
    },
}

const actions = {
    setActiveSubjectTag({ commit }, activeSubjectTag) {
        commit('SET_ACTIVE_SUBJECT_TAG', activeSubjectTag)
    },
    setActiveTags({ commit }, activeTags) {
        commit('SET_ACTIVETAGS', activeTags)
    },
    setCurrentPage({ commit }, page) {
        commit('SET_CURRENT_PAGE', page)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
