import getDeviceInfo from '@/utils/device'

const serverPath = process.env.VUE_APP_SEVER_PATH
const wxAppid = process.env.VUE_APP_WX_APPID
const aliAppid = process.env.VUE_APP_ALI_APPID
const projectPath = process.env.VUE_APP_PROJECT_PATH

const state = {
    SEVER_PATH_NUWA: `${serverPath}/nuwa-api`,
    SEVER_PATH_BEGONIA: `${serverPath}/begonia-api`,
    SEVER_PATH_CUPID: `${serverPath}/cupid-api`,
    WX_APPID: wxAppid,
    ALI_APPID: aliAppid,
    PROJECT_PATH: projectPath,
    deviceInfo: {
        mobile: false,
        wechat: false,
        ios: false,
        android: false,
        pc: true,
        wxMini: false,
        baiduSwan: false,
        tabletPc: false,
        UA: '',
    },
}

const mutations = {
    SET_DEVICE_INFO: (state, info) => {
        state.deviceInfo = info
    },
}
const actions = {
    setDeviceInfo({ commit }) {
        commit('SET_DEVICE_INFO', getDeviceInfo())
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
